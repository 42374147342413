import Modal from './Modal';
import Popup from './Popup';
import Body from './Body';
import Overlay from './Overlay';

Modal.Popup = Popup;
Modal.Body = Body;
Modal.Overlay = Overlay;

export { Modal };
